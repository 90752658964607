<template>
  <form action=" ">
    <van-search
      :placeholder="placeholder"
      :model-value="modelValue"
      shape="square"
      :show-action="showAction"
      background="rgb(245, 245, 245)"
      @search="onSearch"
      @clear="onClear"
      @update:model-value="onChange"
    >
      <template #[item]="data" v-for="item in Object.keys($slots)">
        <slot :name="item" v-bind="data || {}"></slot>
      </template>
    </van-search>
  </form>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Search",
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    showAction: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "请输入内容",
    },
  },
  emits: ["onSearch", "update:modelValue"],
  setup(props, { emit }) {
    console.log(props);
    const onSearch = (value) => {
      console.log("onSearch---value", value);
      emit("onSearch", value);
    };
    const onChange = (value) => {
      emit("update:modelValue", value);
    };
    const onClear = () => {
      emit("onSearch", "");
    };
    return {
      onSearch,
      onChange,
      onClear,
    };
  },
});
</script>

<style lang="scss" scoped></style>
