<template>
  <Page is-header>
    <MemberInfo :info="memberDetailInfo" />
    <van-sticky offset-top="1.174rem">
      <van-tabs
        v-model:active="activeTab"
        background="#ffffff"
        color="#1890FF"
        title-active-color="#1890FF"
      >
        <van-tab
          title="余币查询"
          :to="{ path: '/member/info/coinList', query: route.query }"
          replace
        />
        <van-tab
          title="余币明细"
          :to="{ path: '/member/info/coinRecord', query: route.query }"
          replace
        />
      </van-tabs>
    </van-sticky>

    <router-view v-if="isRenderRoterView" v-slot="{ Component }">
      <keep-alive :max="3" :exclude="[]">
        <component
          :is="Component"
          :member-detail-info="memberDetailInfo"
          @change-info="requestRemoteDetail"
        />
      </keep-alive>
    </router-view>
    <van-empty v-else image="search" description="暂未获取到信息" />
  </Page>
</template>

<script>
import { defineComponent, onActivated, onDeactivated, ref } from "vue";
import MemberInfo from "../components/MemberInfo.vue";
import { useRoute, useRouter } from "vue-router";
import { getMemberInfoApi } from "@/api/member";

const RouteActive = ["CoinList", "CoinRecord"];
export default defineComponent({
  name: "MemberDetail",
  components: {
    MemberInfo,
  },
  setup() {
    const route = useRoute();
    const activeTab = ref(0);
    const isRenderRoterView = ref(false);
    const memberCode = ref("");
    const memberDetailInfo = ref({});

    onActivated(async () => {
      try {
        const { currentRoute } = useRouter();
        activeTab.value = RouteActive.indexOf(currentRoute.value.name);
        memberCode.value = route.query.memberCode;
        await getMemberDetail({
          siteIdList: [],
        });
        isRenderRoterView.value = true;
      } catch (error) {
        console.log("error", error);
        memberDetailInfo.value = {};
      }
    });
    onDeactivated(() => {
      isRenderRoterView.value = false;
    });
    const requestRemoteDetail = (siteIdList) => {
      console.log("siteIdList", siteIdList);
      getMemberDetail({
        siteIdList,
      });
    };
    // 获取详情用户锚点回显
    const getMemberDetail = async (params) => {
      try {
        const { content, code } = await getMemberInfoApi({
          memberCode: memberCode.value,
          ...params,
        });
        if (code === 200) {
          memberDetailInfo.value = content;
          return Promise.resolve(content);
        }
      } catch (error) {
        console.log("error", error);
        return Promise.reject(error);
      }
    };
    return {
      activeTab,
      route,
      memberDetailInfo,
      isRenderRoterView,
      requestRemoteDetail,
    };
  },
});
</script>

<style lang="scss" scoped></style>
