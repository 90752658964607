import request from "@/request/interceptors";

// 获取会员列表
export function getMemberListApi(data) {
  return request({
    url: "mch/data/consumer/info/v1/list/page",
    method: "post",
    data,
  });
}

// 获取场地余币列表
export function getCoinListBySiteIdApi(data) {
  return request({
    url: "mch/data/consumer/account/info/v1/list/page",
    method: "post",
    data,
  });
}

// 获取会员详情
export function getMemberInfoApi(data) {
  return request({
    url: "mch/data/consumer/info/v1/detail",
    method: "post",
    data,
  });
}

// 获取余币明细列表
export function getCoinRecordListApi(data) {
  return request({
    url: "mch/data/consumer/account/record/v1/list/page",
    method: "post",
    data,
  });
}

// 调整余币
export function updateCoinCountApi(data) {
  return request({
    url: "mch/user/consumer/coin/v1/adjust",
    method: "post",
    data,
  });
}

// 清空余币
export function clearAllCoinCountApi(data) {
  return request({
    url: "mch/user/consumer/coin/v1/empty",
    method: "post",
    data,
  });
}
