/**
 * 预览图片
 * params: String[]
 * 注：可访问图片路径
 */
import { ImagePreview, Toast } from "vant";

export const imagePreviewer = (images, options = {}) => {
  if (!Array.isArray(images) || !images.length) {
    Toast("请选择预览的图片");
    return false;
  }
  ImagePreview({
    images,
    ...options,
  });
};
