<template>
  <div class="u-flex u-p-8 u-row-between bg-white">
    <div
      class="filter-point u-flex bg-white u-row-between van-hairline--surround"
      @click="isShowSitePick = true"
    >
      <span class="site-name van-ellipsis">
        {{ siteIdList.map((s) => s.siteName).join(",") || "全部场地" }}
      </span>
      <van-icon
        :class="[
          isShowSitePick ? 'arrow-up' : 'arrow-down',
          'arrow-right',
          'u-m-l-6',
        ]"
        name="arrow"
      />
    </div>
  </div>
  <div class="u-p-b-30">
    <van-list
      v-model:loading="recordLoading"
      :finished="recordFinished"
      finished-text="只展示近30天余币明细～"
      @load="coinRecordLoadMore"
    >
      <template v-if="coinRecordList.length">
        <template v-for="(item, index) in coinRecordList" :key="index">
          <div
            class="u-flex u-row-between u-col-top u-p-12 bg-white van-hairline--bottom"
          >
            <div class="u-flex-1">
              <p class="van-ellipsis">
                {{ item.itemTitle || "--" }}
              </p>
              <p class="u-content-color u-m-t-10 u-font-14">
                {{ item.itemDesc }}
              </p>
              <p class="u-tips-color u-m-t-10">{{ item.handleTime }}</p>
            </div>
            <span
              class="u-m-l-10"
              :class="[item.handle === '+' ? 'u-color-orange' : '']"
            >
              {{ item.handle }}{{ item.coinNum }}币
            </span>
          </div>
        </template>
      </template>
      <template v-else>
        <van-empty image="search" description="" />
      </template>
    </van-list>
  </div>
  <!-- 选择场地 -->
  <PopBottomList
    v-model:visible="isShowSitePick"
    pop-type="site"
    :multiple="true"
    @save="handleSavePoint"
  />
</template>

<script>
import PopBottomList from "@/components/PopBottomList.vue";
import { defineComponent, onActivated, ref } from "vue";
import { getCoinRecordListApi } from "@/api/member";
export default defineComponent({
  name: "CoinRecord",
  props: {
    memberDetailInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    PopBottomList,
  },
  emits: ["change-info"],
  setup(props) {
    const isShowSitePick = ref(false);
    const siteIdList = ref([]);
    const recordLoading = ref(false);
    const recordPage = ref(1);
    const recordFinished = ref(false);
    const coinRecordList = ref([]);

    onActivated(() => {
      try {
        onSearch();
      } catch (error) {
        console.log("error", error);
      }
    });
    const coinRecordLoadMore = () => {
      recordPage.value++;
      getCoinRecordDetail();
    };
    // 获取余币明细
    const getCoinRecordDetail = async () => {
      try {
        recordLoading.value = true;
        const { content, code } = await getCoinRecordListApi({
          page: recordPage.value,
          pageSize: 10,
          siteIdList: siteIdList.value.map((i) => i.siteId),
          userId: props.memberDetailInfo.userId,
        });
        if (code === 200) {
          console.log("content", content);
          const { totalSize, currentList } = content;
          coinRecordList.value = coinRecordList.value.concat(currentList);
          recordFinished.value = Math.ceil(totalSize / 10) <= recordPage.value;
          recordLoading.value = false;
        }
      } catch (error) {
        console.log("error", error);
        recordLoading.value = false;
        recordFinished.value = true;
      }
    };
    const handleSavePoint = (selected, isAll) => {
      console.log(selected);
      if (isAll) {
        // 选择全部场地
        siteIdList.value = [];
      } else {
        siteIdList.value = selected;
      }
      onSearch();
      isShowSitePick.value = false;
    };
    const onSearch = () => {
      coinRecordList.value = [];
      recordPage.value = 1;
      getCoinRecordDetail();
    };
    return {
      recordLoading,
      recordFinished,
      coinRecordList,
      isShowSitePick,
      siteIdList,
      handleSavePoint,
      coinRecordLoadMore,
      getCoinRecordDetail,
    };
  },
});
</script>
<style lang="scss" scoped>
.filter-point {
  padding: 5px 6px;
  color: #333333;
  max-width: 100px;
  border-radius: var(--van-border-radius-sm);
  margin-right: 10px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: normal;
  line-height: var(--van-cell-line-height);
}
</style>
