<template>
  <van-popup
    :show="visible"
    teleport="body"
    class="pop-bottom"
    position="bottom"
    :lock-scroll="true"
    :close-on-popstate="true"
    :close-on-click-overlay="false"
    @click-overlay="close"
  >
    <div class="">
      <div class="pick-header u-flex content">
        <div class="header-item" />
        <div class="header-item u-text-center u-main-color u-font-15">
          选择{{ Title[popType] }}
        </div>
        <div class="header-item u-flex u-row-right" @click="close">
          <van-icon name="cross" color="#666666" size="18" />
        </div>
      </div>
      <!-- 搜索框 -->
      <Search
        v-if="search"
        v-model.trim="searchKey"
        :placeholder="Placeholder[popType]"
        :show-action="true"
        @on-search="handleSearch"
      >
        <template #action>
          <span class="u-font-14 u-theme-color" @click="handleSearch"
            >搜索</span
          >
        </template>
      </Search>
      <ul v-if="tempList.length" class="type-list">
        <div v-if="loading" class="van-picker__loading">
          <van-loading type="spinner" size="42" />
        </div>
        <li
          v-for="item in tempList"
          :key="item[DefaultProp[popType]['id']]"
          class="u-flex point-item van-hairline--top"
          @click="handleSelectItem(item)"
        >
          <div class="u-flex">
            <van-icon
              v-if="item.select"
              class-prefix="iconfont"
              name="success"
              size="20"
              color="#027AFF"
            />
            <van-icon
              v-else
              class-prefix="iconfont"
              name="circle"
              color="#999999"
              size="20"
            />
          </div>
          <div class="u-flex-1 u-m-l-10 u-flex u-row-between u-line-1">
            <div class="u-flex-1 u-line-1">
              {{ item[DefaultProp[popType]["name"]] }}
            </div>
            <span
              v-if="popType === 'product' && item.productImage"
              class="u-theme-color u-font-14"
              @click="imagePreviewer(item.productImage)"
              >主板图</span
            >
          </div>
        </li>
      </ul>
      <div v-else class="type-list">
        <div v-if="loading" class="van-picker__loading">
          <van-loading type="spinner" size="42" />
        </div>
        <van-empty image="search" :description="EmptyText[popType]" />
      </div>
      <div class="content u-flex">
        <div v-if="multiple" class="select-all u-flex u-m-r-10">
          <van-icon
            v-if="selectAll"
            class-prefix="iconfont"
            name="success"
            size="16"
            color="#027AFF"
            @click="handleClickSelectAll(false)"
          />
          <van-icon
            v-else
            class-prefix="iconfont"
            name="circle"
            size="16"
            @click="handleClickSelectAll(true)"
          />
          <span class="u-font-14 u-main-color u-m-l-4">全选</span>
        </div>
        <div class="u-flex-1 u-p-5 u-p-b-5">
          <van-button
            round
            type="primary"
            :disabled="!tempList.length || loading"
            @click="handleSave"
            >保存</van-button
          >
        </div>
      </div>
    </div>
  </van-popup>
</template>

<script>
// TODO...
// 这个组件需要优化，没有时间做了，应该把接口和交互在父组件处理
import { imagePreviewer } from "@/hooks/useImagePreview";
import { isOperator } from "@/hooks/useRole";
import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
  onDeactivated,
  inject,
} from "vue";
import Search from "@/components/Search.vue";
import {
  getSiteListApi,
  getGoodsListApi,
  getPackageListApi,
} from "@/api/device";
import {
  getBankListByNameApi,
  getBankBranchListApi,
  getUserRoleListApi,
} from "@/api/user";
import {
  factoryGetBankByNameApi,
  factoryGetBranchBankByNameApi,
  factoryGetDeviceProductApi,
} from "@/api/vendor";
import { Toast } from "vant";
const Apis = {
  site: getSiteListApi,
  goods: getGoodsListApi,
  package: getPackageListApi,
  bank: isOperator() ? getBankListByNameApi : factoryGetBankByNameApi,
  branch: isOperator() ? getBankBranchListApi : factoryGetBranchBankByNameApi,
  role: getUserRoleListApi,
  product: factoryGetDeviceProductApi,
};
const DefaultProp = {
  site: { name: "siteName", id: "siteId" },
  goods: { name: "skuName", id: "skuId" },
  package: { name: "templateName", id: "id" },
  bank: { name: "bankName", id: "id" },
  branch: { name: "branchName", id: "id" },
  role: { name: "roleName", id: "id" },
  product: { name: "productName", id: "id" },
};
const Title = {
  site: "场地",
  goods: "商品",
  package: "套餐",
  bank: "开户银行",
  branch: "支行网点",
  role: "角色",
  product: "主控型号",
};
const Placeholder = {
  site: "请输入场地名称搜索",
  goods: "请输入商品名称搜索",
  package: "请输入套餐名称搜索",
  bank: "请输入开户银行名称搜索",
  branch: "请输入支行网点名称搜索",
  role: "请输入角色名称搜索",
  product: "请输入主控型号名称搜索",
};
const EmptyText = {
  site: "暂无场地可选择",
  goods: "暂无商品可选择",
  package: "暂无套餐可选择",
  bank: "当前城市暂无网点或输入银行名称搜索",
  branch: "当前城市暂无网点或输入支行名称搜索",
  role: "暂无角色可选择",
  product: "暂无主控型号可选择",
};
export default defineComponent({
  name: "PopBottomList",
  components: {
    Search,
  },
  props: {
    // 显示隐藏
    visible: {
      require: true,
      type: Boolean,
      default: false,
    },
    // 是否多选
    multiple: {
      type: Boolean,
      default: false,
    },
    // 类型
    popType: {
      require: true,
      type: String,
      default: "site",
    },
    // 回显
    defaultValue: {
      type: Array, // 兼容多选
      default: () => [],
    },
    // 是否显示全部xx
    singleAll: {
      type: Boolean,
      default: false,
    },
    // 额外参数
    params: {
      require: false,
      type: Object,
      default: () => {
        return {};
      },
    },
    // 是否组件加载就请求接口
    first: {
      type: Boolean,
      default: true,
    },
    // 是否显示搜索框
    search: {
      type: Boolean,
      default: true,
    },
    // 是否至少选择一个
    atLeastOne: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:visible", "save"],
  setup(props, { emit }) {
    console.log(props);
    const ImagePrefix = inject("ImagePrefix");
    const searchKey = ref("");
    const loading = ref(false);
    let tempList = ref([]);
    // 全选
    const selectAll = computed(() => {
      return tempList.value.length && tempList.value.every((i) => i.select);
    });
    // 已选中
    const selected = computed(() => {
      return tempList.value.filter((i) => i.select);
    });
    // 初始化
    onMounted(() => {
      searchKey.value = "";
      props.first && getRemoteListAction();
    });
    onDeactivated(() => {
      close();
    });
    // 回显监听
    watch(
      () => props.defaultValue,
      (n) => {
        console.log("n", n);
        if (n.length && n[0] !== "") {
          tempList.value.forEach((k) => {
            k["select"] = false;
            n.forEach((value) => {
              if (value === k[DefaultProp[props.popType].id]) {
                k["select"] = true;
              }
            });
          });
        } else {
          tempList.value.forEach((k, i) => {
            k["select"] = false;
            props.atLeastOne && !i && (k["select"] = true);
          });
        }
      },
      { deep: true }
    );
    // 请求接口方法
    const getRemoteListAction = async () => {
      loading.value = true;
      try {
        console.log("props.params", props.params);
        const { code, content } = await Apis[props.popType]({
          [DefaultProp[props.popType].name]: searchKey.value,
          ...props.params,
        });
        if (code === 200) {
          const list = Array.isArray(content) ? content : content.currentList;
          let m = list.map((l, i) => {
            if (l.productImage) {
              let productImages = l.productImage.split(",") || [];
              l.productImage = productImages.map((m) => {
                return `${ImagePrefix}${m}`;
              });
            }
            return {
              ...l,
              select: !i,
            };
          });
          // 场地需要单独处理，当当前是场地列表并且单选并且有长度的情况下，需要在列表第一项添加全部场地
          // props.popType === "site" && !props.multiple && m.length
          if (props.singleAll) {
            // 如果有需要将原本第一项的选中状态取消
            m[0] && (m[0].select = false);
            const All = {
              [DefaultProp[props.popType].id]: "",
              [DefaultProp[props.popType].name]: `全部${Title[props.popType]}`,
              select: true,
            };
            m.unshift(All);
          }
          tempList.value = m;
          loading.value = false;
        }
      } catch (error) {
        console.log("error", error);
        loading.value = false;
      }
    };
    // 搜索
    const handleSearch = () => {
      console.log(searchKey.value);
      getRemoteListAction();
    };
    // 关闭弹窗
    const close = () => {
      emit("update:visible", false);
    };
    // 全选
    const handleClickSelectAll = (is) => {
      tempList.value.forEach((p) => {
        p.select = is;
      });
    };
    // 选中某个选项
    const handleSelectItem = (item) => {
      console.log("item", item);
      tempList.value.forEach((p) => {
        if (
          props.multiple &&
          item[DefaultProp[props.popType]["id"]] ===
            p[DefaultProp[props.popType]["id"]]
        ) {
          p.select = !p.select;
        }
        if (!props.multiple) {
          if (
            item[DefaultProp[props.popType]["id"]] ===
            p[DefaultProp[props.popType]["id"]]
          ) {
            p.select = true;
          } else {
            p.select = false;
          }
        }
      });
    };
    // 保存将选中的值返回
    const handleSave = () => {
      if (!selected.value.length) {
        Toast(`请选择${Title[props.popType]}`);
        return false;
      }
      emit("save", selected.value, selectAll.value);
    };
    return {
      loading,
      searchKey,
      selectAll,
      tempList,
      DefaultProp,
      Title,
      Placeholder,
      EmptyText,
      imagePreviewer,
      handleSearch,
      handleClickSelectAll,
      handleSelectItem,
      close,
      handleSave,
      getRemoteListAction,
    };
  },
});
</script>

<style lang="scss" scoped>
.pop-bottom {
  .type-list {
    height: 310px;
    overflow-y: scroll;
    position: relative;
    .loading {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ffffff;
      opacity: 0.8;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .point-item {
    padding: 12px;
    font-size: 16px;
  }
  .header-item {
    width: 33.3%;
    flex: 1;
    height: 40px;
    line-height: 40px;
  }
}
</style>
