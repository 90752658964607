<template>
  <div class="bg-white u-m-b-12" @click="onClickMemberInfo">
    <div class="u-flex van-hairline--bottom u-p-12">
      <van-image
        round
        width="72"
        height="72"
        fit="fill"
        :src="info?.headImgUrl || `${ImagePrefix}AP1661501057398`"
      />
      <div class="u-flex-1 u-m-l-12">
        <p class="u-font-16 van-ellipsis u-flex">
          <van-icon
            class-prefix="iconfont"
            :name="['touxiang', 'nan', 'nv'][Number(info.gender)]"
            color="#999999"
            size="16"
          />
          <span class="u-m-l-8">{{ info.nickName || "--" }}</span>
        </p>
        <div class="u-flex u-m-t-10">
          <span class="u-m-r-12 u-tips-color u-font-12">
            ID: {{ info.memberCode || "--" }}
          </span>
        </div>
        <div class="u-flex u-m-t-10">
          <Tag
            :text="info.lastConsumeDate || '--'"
            bg="#F0F2F5"
            color="#999999"
          />
        </div>
      </div>
    </div>
    <van-row class="u-p-12" justify="space-around">
      <van-col class="u-text-center u-color-orange" span="12">
        余币：{{ info.totalCoin || 0 }}
      </van-col>
      <van-col class="u-text-center u-color-orange" span="12">
        累计支付：{{ amountFmt(info.totalAmount) }}
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { amountFmt } from "@/utils/format";
import Tag from "@/components/Tag";
export default defineComponent({
  name: "MemberInfo",
  components: { Tag },
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["ImagePrefix"],
  emits: ["handler"],
  setup(props, { emit }) {
    const onClickMemberInfo = () => {
      emit("handler", props.info);
    };
    return {
      amountFmt,
      onClickMemberInfo,
    };
  },
});
</script>

<style lang="scss" scoped></style>
