<template>
  <div class="u-flex u-row-between bg-white clear-all-coin">
    <div
      class="filter-point u-flex bg-white u-row-between van-hairline--surround"
      @click="isShowSitePick = true"
    >
      <span class="site-name van-ellipsis">
        {{ siteIdList.map((s) => s.siteName).join(",") || "全部场地" }}
      </span>
      <van-icon
        :class="[
          isShowSitePick ? 'arrow-up' : 'arrow-down',
          'arrow-right',
          'u-m-l-6',
        ]"
        name="arrow"
      />
    </div>
    <van-button
      v-if="useAuth('B050102')"
      type="primary"
      class="member-detail-btn"
      round
      @click="handleClearAllCoins"
    >
      清空余币
    </van-button>
  </div>
  <div class="u-p-b-30">
    <van-list
      v-model:loading="listLoading"
      :finished="listFinished"
      finished-text="拉到底部啦～"
      @load="coinListLoadMore"
    >
      <template v-if="coinPageList.length">
        <template v-for="(item, index) in coinPageList" :key="index">
          <van-cell
            center
            :title="item.siteName"
            :label="`余币：${item.surplusCoin}`"
          >
            <template #value>
              <van-button
                v-if="useAuth('B050102')"
                type="primary"
                class="member-detail-btn"
                round
                @click="onChangeCoin(item)"
              >
                调整余币
              </van-button>
            </template>
          </van-cell>
        </template>
      </template>
      <template v-else>
        <van-empty image="search" description="" />
      </template>
    </van-list>
  </div>
  <!-- 调整余币 -->
  <van-dialog
    v-model:show="isShowCoinChange"
    title="调整余币"
    show-cancel-button
    confirm-button-color="#027AFF"
    :before-close="handleSubmitChangeCoin"
  >
    <div class="u-p-24">
      <van-radio-group
        v-model="changeCoinInfo.handle"
        direction="horizontal"
        class="u-row-around u-m-b-12"
      >
        <van-radio name="1">减币</van-radio>
        <van-radio name="2">加币</van-radio>
      </van-radio-group>
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <div class="van-hairline--surround">
        <van-field
          v-model="changeCoinInfo.coin"
          type="digit"
          label=""
          placeholder="请输入需要调整的数量"
        />
      </div>
    </div>
  </van-dialog>
  <!-- 选择场地 -->
  <PopBottomList
    v-model:visible="isShowSitePick"
    pop-type="site"
    :multiple="true"
    @save="handleSavePoint"
  />
</template>

<script>
import PopBottomList from "@/components/PopBottomList.vue";
import { defineComponent, onActivated, ref, reactive, inject } from "vue";
import {
  getCoinListBySiteIdApi,
  clearAllCoinCountApi,
  updateCoinCountApi,
} from "@/api/member";
import { Dialog, Toast } from "vant";
export default defineComponent({
  name: "CoinList",
  props: {
    memberDetailInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  inject: ["useAuth"],
  components: {
    PopBottomList,
  },
  emits: ["change-info"],
  setup(props, { emit }) {
    const isShowSitePick = ref(false);
    const isShowCoinChange = ref(false);
    const siteIdList = ref([]);
    const useAuth = inject("useAuth");
    const listLoading = ref(false);
    const listPage = ref(1);
    const listFinished = ref(false);
    const coinPageList = ref([]); // 获取余币列表
    const currentCoinItem = ref({}); // 当前的item
    const changeCoinInfo = reactive({
      handle: "1",
      coin: "",
    });

    onActivated(() => {
      try {
        changeCoinInfo.coin = "";
        changeCoinInfo.handle = "1";
        onSearch();
      } catch (error) {
        console.log("error", error);
      }
    });

    const coinListLoadMore = () => {
      listPage.value++;
      getCoinList();
    };
    const getCoinList = async () => {
      try {
        // 判断权限码
        if (!useAuth("B050101")) {
          Toast("暂无权限");
          return false;
        }
        listLoading.value = true;
        const { content, code } = await getCoinListBySiteIdApi({
          page: listPage.value,
          pageSize: 10,
          siteIdList: siteIdList.value.map((i) => i.siteId),
          userId: props.memberDetailInfo.userId,
        });
        if (code === 200) {
          console.log("content", content);
          const { totalSize, currentList } = content;
          coinPageList.value = coinPageList.value.concat(currentList);
          listFinished.value = Math.ceil(totalSize / 10) <= listPage.value;
          listLoading.value = false;
        }
      } catch (error) {
        console.log("error", error);
        listLoading.value = false;
        listFinished.value = true;
      }
    };
    const handleSavePoint = (selected, isAll) => {
      console.log(selected);
      if (isAll) {
        // 选择全部场地
        siteIdList.value = [];
      } else {
        siteIdList.value = selected;
      }
      onSearch();
      emit(
        "change-info",
        siteIdList.value.map((i) => i.siteId)
      );
      isShowSitePick.value = false;
    };

    const onSearch = () => {
      coinPageList.value = [];
      listPage.value = 1;
      getCoinList();
    };
    const handleClearAllCoins = () => {
      if (!props.memberDetailInfo.totalCoin)
        return Toast("当前余币为0，无需清空");
      Dialog.confirm({
        title: "清空用户余币？",
        message: `将清空用户在所有场地的余币${props.memberDetailInfo.totalCoin}个`,
        confirmButtonColor: "#027AFF",
        async beforeClose(action) {
          if (action === "confirm") {
            try {
              await clearAllCoinCountApi({
                userId: props.memberDetailInfo.userId,
              });
              // 修改详情的余币数量
              emit(
                "change-info",
                siteIdList.value.map((i) => i.siteId)
              );
              onSearch();
              Toast("清空成功");
              return Promise.resolve(true);
            } catch (error) {
              console.log("error", error);
              return Promise.resolve(false);
            }
          } else {
            return true;
          }
        },
      });
    };
    const onChangeCoin = (item) => {
      changeCoinInfo.coin = "";
      changeCoinInfo.handle = "1";
      currentCoinItem.value = item;
      isShowCoinChange.value = true;
    };
    const handleSubmitChangeCoin = async (action) => {
      if (action === "confirm") {
        try {
          // 判断空值
          if (changeCoinInfo.coin === "" || changeCoinInfo.coin === "0") {
            Toast("数量不能为空或者0");
            return Promise.resolve(false);
          }
          // 减币判断范围
          if (
            changeCoinInfo.handle === "1" &&
            changeCoinInfo.coin > currentCoinItem.value.surplusCoin
          ) {
            Toast("调整数量不能超过当前余币数量");
            return Promise.resolve(false);
          }
          // 加币判断范围
          if (changeCoinInfo.handle === "2" && changeCoinInfo.coin > 9999) {
            Toast("调整数量不能超过9999");
            return Promise.resolve(false);
          }
          await updateCoinCountApi({
            ...changeCoinInfo,
            userId: props.memberDetailInfo.userId,
            siteId: currentCoinItem.value.siteId,
          });
          // 修改当前列表的余币数量
          const itemValue =
            changeCoinInfo.handle === "1"
              ? currentCoinItem.value.surplusCoin - changeCoinInfo.coin
              : Number(currentCoinItem.value.surplusCoin) +
                Number(changeCoinInfo.coin);
          currentCoinItem.value.surplusCoin = itemValue;
          emit(
            "change-info",
            siteIdList.value.map((i) => i.siteId)
          );
          Toast("调整成功");
          return Promise.resolve(true);
        } catch (error) {
          console.log("error", error);
          return Promise.resolve(false);
        }
      } else {
        return Promise.resolve(true);
      }
    };
    return {
      changeCoinInfo,
      listLoading,
      listFinished,
      coinPageList,
      isShowSitePick,
      isShowCoinChange,
      siteIdList,
      onChangeCoin,
      handleSavePoint,
      coinListLoadMore,
      handleClearAllCoins,
      handleSubmitChangeCoin,
    };
  },
});
</script>
<style lang="scss" scoped>
.clear-all-coin {
  padding: 8px var(--van-cell-horizontal-padding);
}
.filter-point {
  padding: 5px 6px;
  color: #333333;
  max-width: 100px;
  border-radius: var(--van-border-radius-sm);
  margin-right: 10px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: normal;
  line-height: var(--van-cell-line-height);
}
.member-detail-btn {
  width: 100px;
  font-size: 14px;
  height: 28px;
}
</style>
