<template>
  <Page is-header>
    <van-sticky offset-top="1.174rem">
      <div class="search">
        <Search
          v-model="memberCode"
          placeholder="请输入/扫描会员码搜索"
          @onSearch="handleSearchMember"
        >
          <!-- <template v-slot:left>
            <div class="u-flex">
              <div
                class="filter-point u-flex bg-white u-row-between van-hairline--surround"
                @click="isShowSitePick = true"
              >
                <span class="site-name van-ellipsis">
                  {{
                    siteIdList.map((s) => s.siteName).join(",") || "全部场地"
                  }}
                </span>
                <van-icon
                  :class="[
                    isShowSitePick ? 'arrow-up' : 'arrow-down',
                    'arrow-right',
                    'u-m-l-6',
                  ]"
                  name="arrow"
                />
              </div>
            </div>
          </template> -->
          <template v-slot:right-icon>
            <van-icon
              name="scan"
              size="24"
              color="#027AFF"
              @click="handleScanMember"
            />
          </template>
        </Search>
      </div>
    </van-sticky>

    <div class="content u-p-b-80">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="loadMore"
      >
        <template v-if="memberList.length">
          <template v-for="item in memberList" :key="item.memberId">
            <MemberInfo :info="item" @handler="handleToMemberDetail" />
          </template>
        </template>
        <template v-else>
          <van-empty image="search" description="" />
        </template>
      </van-list>
    </div>
    <!-- 选择场地 -->
    <!-- <PopBottomList
      v-model:visible="isShowSitePick"
      pop-type="site"
      :multiple="true"
      @save="handleSavePoint"
    /> -->
  </Page>
</template>

<script>
import { defineComponent, onActivated, ref, inject } from "vue";
// import PopBottomList from "@/components/PopBottomList.vue";
import { useActions } from "@/hooks/useVuex";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import Search from "@/components/Search.vue";
import MemberInfo from "./components/MemberInfo.vue";
import { getMemberListApi, getMemberInfoApi } from "@/api/member";
import { Toast } from "vant";
export default defineComponent({
  name: "MemberList",
  inject: ["useAuth"],
  components: {
    Search,
    // PopBottomList,
    MemberInfo,
  },
  setup() {
    const isRender = ref(true);
    const currentMemberInfo = ref({}); // 当前点击去详情的锚点
    const router = useRouter();
    console.log("router", router);
    const memberCode = ref("");
    const useAuth = inject("useAuth");
    const page = ref(1);
    const finished = ref(false);
    const loading = ref(false);
    // const isShowSitePick = ref(false);
    const memberList = ref([]);
    // const siteIdList = ref([]);

    const { handleScanCode } = useActions("app", ["handleScanCode"]);

    onBeforeRouteLeave((to) => {
      if (to.name === "CoinList") {
        isRender.value = false;
      } else {
        isRender.value = true;
      }
    });

    onActivated(() => {
      if (isRender.value) {
        initMemberList();
      } else {
        isRender.value = true;
        console.log("返回");
        getMemberDetail();
      }
    });
    // 重置
    const initMemberList = () => {
      currentMemberInfo.value = {};
      memberList.value = [];
      page.value = 1;
      finished.value = false;
      loading.value = false;
      getMemberListAction();
    };
    const loadMore = () => {
      page.value++;
      getMemberListAction();
    };
    const handleScanMember = async () => {
      const memberCode = await handleScanCode((value) =>
        value.replace(/[\r \n]/g, "")
      );
      handleToMemberDetail({
        memberCode,
      });
    };
    // 获取列表
    const getMemberListAction = async () => {
      // 判断权限码
      if (!useAuth("B050101")) {
        Toast("暂无权限");
        return false;
      }
      loading.value = true;
      try {
        const {
          code,
          content: { currentList, totalSize },
        } = await getMemberListApi({
          page: page.value,
          pageSize: 10,
          memberCode: memberCode.value,
          // siteIdList: siteIdList.value.map((i) => i.siteId),
        });
        if (code === 200) {
          loading.value = false;
          console.log("content", currentList, totalSize);
          if (page.value === 1) {
            memberList.value = currentList;
          } else {
            memberList.value = memberList.value.concat(currentList);
          }
          finished.value = Math.ceil(totalSize / 10) <= page.value;
        }
      } catch (error) {
        console.log(error);
        loading.value = false;
        finished.value = true;
      }
    };
    // 获取详情用户锚点回显
    const getMemberDetail = async () => {
      try {
        const { content, code } = await getMemberInfoApi({
          memberCode: currentMemberInfo.value.memberCode,
          siteIdList: [],
        });
        if (code === 200) {
          const Current = memberList.value.find(
            (i) => i.memberCode === content.memberCode
          );
          Current.totalCoin = content.totalCoin;
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    const handleSearchMember = () => {
      page.value = 1;
      getMemberListAction();
    };
    // const handleSavePoint = (selected, isAll) => {
    //   console.log(selected);
    //   if (isAll) {
    //     // 选择全部场地
    //     siteIdList.value = [];
    //   } else {
    //     siteIdList.value = selected;
    //   }
    //   handleSearchMember();
    //   isShowSitePick.value = false;
    // };
    const handleToMemberDetail = (info) => {
      currentMemberInfo.value = info;
      router.push({
        path: "/member/info",
        query: {
          memberCode: info.memberCode,
        },
      });
    };
    return {
      memberCode,
      memberList,
      page,
      finished,
      loading,
      // siteIdList,
      // isShowSitePick,
      // event
      handleScanMember,
      loadMore,
      // handleSavePoint,
      initMemberList,
      handleSearchMember,
      getMemberListAction,
      handleToMemberDetail,
    };
  },
});
</script>

<style lang="scss" scoped>
.filter-point {
  padding: 5px 6px;
  color: #333333;
  max-width: 100px;
  border-radius: var(--van-border-radius-sm);
  margin-right: 10px;
  font-size: 14px;
  box-sizing: border-box;
  line-height: normal;
  line-height: var(--van-cell-line-height);
}
</style>
